import { EnvironmentService } from '../services/EnvironmentService';

let baseUrl = 'https://api.theaissist.com'; // Default to production
let subscribers = [];

export const setBaseUrl = async (userEmail) => {
  try {
    const newBaseUrl = await EnvironmentService.getApiUrl(userEmail);
    if (newBaseUrl !== baseUrl) {
      baseUrl = newBaseUrl;
      // Notify all subscribers of the URL change
      subscribers.forEach(callback => callback(baseUrl));
    }
  } catch (error) {
    console.error('Error setting base URL:', error);
    // Ensure we default to production on error
    baseUrl = 'https://api.theaissist.com';
  }
};

export const subscribeToBaseUrl = (callback) => {
  subscribers.push(callback);
  return () => {
    subscribers = subscribers.filter(cb => cb !== callback);
  };
};

export const getBaseUrl = () => baseUrl;

// API endpoints
export const getLogsApi = () => `${baseUrl}/logs`;
export const getQuestionLogsApi = () => `${baseUrl}/question_logs`;
export const getKillApi = () => `${baseUrl}/kill`;
export const getMessagesApi = () => `${baseUrl}/messages`;
export const getQuestionMessagesApi = () => `${baseUrl}/question_messages`;
export const getPromptApi = () => `${baseUrl}/prompt`;
export const getQuestionApi = () => `${baseUrl}/question`;
export const getSummaryApi = () => `${baseUrl}/summarise`;
export const getCreateQuestionApi = () => `${baseUrl}/create_question`;
export const getTokensApi = () => `${baseUrl}/tokens`;
export const getPlansApi = () => `${baseUrl}/plans`;
export const getKillQuestionApi = () => `${baseUrl}/kill_question`;
export const getNavigatorApi = () => `${baseUrl}/navigator`;
export const getNavigatorMessagesApi = () => `${baseUrl}/navigator_messages`;
