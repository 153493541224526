import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthProvider from './components/AuthProvider';
import store from './store';
import App from './App';
import './index.css';
import { AuthStateManager } from './utils/authStateManager';

const Auth0ProviderWithConfig = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.theaissist.com';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      scope="openid profile email offline_access"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      advancedOptions={{
        defaultScope: "openid profile email offline_access",
      }}
      onRedirectCallback={(appState) => {
        window.history.replaceState(
          {},
          document.title,
          appState?.returnTo || window.location.pathname
        );
      }}
      onError={async (error) => {
        console.error('Auth0 error:', error);
        await AuthStateManager.cleanupAuth();
        window.location.href = window.location.origin;
      }}
    >
      <AuthProvider>
        {children}
      </AuthProvider>
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0ProviderWithConfig>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Auth0ProviderWithConfig>
);
