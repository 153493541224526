export class EnvironmentService {
  static getApiUrl(userEmail) {
    // Always default to production unless explicitly matching @theaissist.com
    if (!userEmail || typeof userEmail !== 'string') {
      return 'https://api.theaissist.com';
    }

    // Strict check for @theaissist.com domain
    return userEmail.trim().toLowerCase().endsWith('@theaissist.com')
      ? 'https://staging-api.theaissist.com'
      : 'https://api.theaissist.com';
  }

  static async getAuthUrl() {
    try {
      // Get client IP using a reliable service
      const response = await fetch('https://api4.ipify.org?format=json');
      const data = await response.json();
      const clientIp = data.ip;

      // Strict IP check for staging - ONLY for authentication
      if (clientIp === '101.56.222.131') {
        return 'https://staging-api.theaissist.com/auth';
      }

      // All other IPs MUST route to production auth
      return 'https://api.theaissist.com/auth';
    } catch (error) {
      // If IP check fails, MUST default to production for security
      console.error('IP check failed, defaulting to production auth:', error);
      return 'https://api.theaissist.com/auth';
    }
  }
}
